<template>
  <div class="mx-auto grid max-w-component grid-cols-1 lg:grid-cols-2">
    <!-- IMAGE -->
    <div
      :class="
        teaser.image_position?.value === 'right'
          ? 'lg:order-2 lg:pl-container-xl 2xl:ml-24 lg:pr-0'
          : 'lg:order-1 lg:pl-0 lg:pr-container-xl 2xl:mr-24'
      "
      class="order-2 p-container xs:p-11 lg:py-0"
    >
      <div
        class="h-full w-full grow-0"
        :class="{ 'lg:h-[80vh]': totalAmountTeasers > 1 }"
      >
        <AppImage
          v-if="teaser.image"
          loading="lazy"
          :src="teaser.image"
          class="max-h-[80vh] w-full grow-0 rounded-lg object-contain object-left lg:mt-16 xl:mt-28"
        />
      </div>
    </div>

    <!-- TEXT -->
    <div
      :class="[
        teaser.image_position?.value === 'right'
          ? 'lg:order-1 lg:-mr-container'
          : ' lg:order-2 lg:-ml-container',
        totalAmountTeasers > 1
          ? 'justify-start pt-24 xl:pt-40 '
          : 'justify-center',
      ]"
      class="teaser-text order-1 flex flex-col"
    >
      <!-- INDEX -->
      <div
        v-if="totalAmountTeasers > 1"
        class="font-sans-heavy-900 z-0 text-[11.25rem] sm:text-[25rem]"
        :class="isBlue ? '!opacity-30' : 'text-second-blue-200'"
        :style="isBlue ? `color: ${indexColor}` : ''"
      >
        {{ index + 1 }}
      </div>
      <!--
      <InViewAnimateMultiple> -->
      <Component
        :is="teaser.heading ? 'h3' : 'h2'"
        v-if="teaser.heading"
        class="font-sans-heavy-900 relative mb-6 max-w-title text-xl sm:mb-9 sm:text-2xl"
      >
        <span v-html="$replaceHtmlTags(teaser.heading)" />
      </Component>
      <!-- </InViewAnimateMultiple> -->

      <!-- <InViewAnimateMultiple> -->
      <div
        class="text ul-list-style relative mr-2 max-w-[65ch] text-sm sm:text-md"
        v-html="teaser.text"
      />
      <!-- </InViewAnimateMultiple> -->

      <!-- <InViewAnimateMultiple> -->
      <div class="hidden lg:block">
        <button
          v-if="isBlue && index < totalAmountTeasers - 1"
          class="focus-default group my-11 hidden w-fit rounded-full text-main-blue transition-colors lg:block"
          aria-label="Scroll to next teaser"
          :class="
            isBlue ? 'text-white' : 'text-main-blue  hover:text-main-blue-hover'
          "
          @click="$emit('next')"
        >
          <ArrowRoundIcon class="h-9 w-9 group-hover:animate-custom-bounce" />
        </button>
      </div>
      <!-- </InViewAnimateMultiple> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },

    teaser: {
      type: Object,
      required: true,
    },

    totalAmountTeasers: {
      type: Number,
      required: true,
    },

    isBlue: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['next'],

  computed: {
    indexColor() {
      if (!this.isBlue) {
        return null
      }
      // the sticky position creates a new stack content therefore the mix blend mode does not work as expected with the background gradient
      // this functions calculate the position like the css property mix-blend-mode: color-dodge;

      // get gradient color at components position
      let color = this.getColorAtPosition(
        '#0465EA',
        '#021C3B',
        this.index,
        this.totalAmountTeasers - 1
      )

      // Apply color dodge blending
      color = this.colorDodgeBlend('#0465EA', color)

      return color
    },
  },

  methods: {
    getColorAtPosition(color1, color2, index, length) {
      // Convert hex colors to RGB
      const rgb1 = this.hexToRgb(color1)
      const rgb2 = this.hexToRgb(color2)

      // Calculate the ratio
      const ratio = index / length

      // Calculate the color at the given position
      const positionRgb = {
        r: Math.round(rgb1.r + (rgb2.r - rgb1.r) * ratio),
        g: Math.round(rgb1.g + (rgb2.g - rgb1.g) * ratio),
        b: Math.round(rgb1.b + (rgb2.b - rgb1.b) * ratio),
      }

      // Convert position RGB values to hex
      const positionColor = this.rgbToHex(
        positionRgb.r,
        positionRgb.g,
        positionRgb.b
      )
      return positionColor
    },

    colorDodgeBlend(color1, color2) {
      // Convert hex colors to RGB
      const rgb1 = this.hexToRgb(color1)
      const rgb2 = this.hexToRgb(color2)

      // Apply color dodge blending algorithm
      const blendedRgb = {
        r: this.applyColorDodge(rgb1.r, rgb2.r),
        g: this.applyColorDodge(rgb1.g, rgb2.g),
        b: this.applyColorDodge(rgb1.b, rgb2.b),
      }

      // Convert blended RGB values to hex
      const blendedColor = this.rgbToHex(
        blendedRgb.r,
        blendedRgb.g,
        blendedRgb.b
      )
      return blendedColor
    },

    applyColorDodge(base, blend) {
      return blend === 255
        ? 255
        : Math.min(255, Math.floor((base * 256) / (255 - blend)))
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null
    },

    componentToHex(c) {
      const hex = c.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    },

    rgbToHex(r, g, b) {
      return (
        '#' +
        this.componentToHex(r) +
        this.componentToHex(g) +
        this.componentToHex(b)
      )
    },
  },
}
</script>
